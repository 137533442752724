<template>
  <div class="page wertschoepfung">
    <progress max="100" value="0"></progress>
    <section class="section section__bg-grey section__vw section__vh">
      <div class="main-banner jarallax">
        <img
          src="../assets/images/wertschoepfung/banner.jpg"
          alt
          class="jarallax-img"
        />
        <div class="banner-content">
          <div class="container">
            <h2>Wertschöpfung für die Region</h2>
          </div>
        </div>
        <div class="main-bubble">
          <div class="bubble bubble__round-white">
            <h4>Das Ziel</h4>
            <h3>ab 2023</h3>
            <h4>Klimaneutral</h4>
          </div>
        </div>
      </div>
      <div class="container container__pw">
        <div class="content-wrapper">
          <h3>Wir fördern Wirtschaftskraft und Klimaschutz</h3>
          <p>
            Landkreise und Kommunen müssen die Voraussetzungen für eine
            möglichst gute Lebensqualität ihrer Bürgerinnen und Bürger schaffen
            und gleichzeitig Vorsorge für deren Zukunft treffen. Als ein
            überwiegend in öffentlicher Hand befindliches Unternehmen sehen wir
            uns als Partner an ihrer Seite. Mit unserem Know-how tragen wir dazu
            bei, die aktuellen großen Herausforderungen wie Klimaschutz,
            Energiewende und Digitalisierung zu meistern.
          </p>
        </div>
        <div class="content-wrapper">
          <h2>Regional engagieren –</h2>
          <h3>Wir sehen uns als Partner der Kommunen</h3>
          <p>
            Wir fühlen uns mitverantwortlich für die Wirtschaftskraft und
            Lebensqualität in der Region, weit über unser originäres Geschäft
            hinaus. So helfen zum Beispiel als Partner Kommunen beim Aufbau
            eines Glasfasernetzes. Rund 3,8 Mio. Euro haben wir selbst in den
            Breitbandausbau investiert und hier sind wir als Berater, Planer
            oder Generalunternehmer aktiv.
          </p>
          <p>
            <strong>
              Schnell klimaneutral – als Energieunternehmen wollen wir Vorreiter
              sein<br />
            </strong>
            Unsere Konzessionsgemeinden unterstützen wir auf dem Weg zur
            gesetzlich verpflichtenden Klimaneutralität bis 2035: Wir bieten
            ihnen Hilfe beim Erstellen von Klimaschutzkonzepten an, ebenso für
            eine nachhaltige Quartiersentwicklung oder Straßenbeleuchtung. Als
            Energieunternehmen haben wir eine Vorreiterrolle bei Klimaschutz und
            Nachhaltigkeit. Unser Umweltmanagement ist EMAS-zertifiziert. Wir
            haben uns das Ziel gesetzt, als Unternehmen bis 2023 klimaneutral zu
            sein.
          </p>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/engagement" tag="div">
          <a class="link link__back"></a>
        </router-link>
        <router-link to="/mitarbeiter" tag="div">
          <a class="link link__next"></a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Wertschoepfung",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/wertschoepfung.scss";
</style>
