<template>
  <div class="page preisentwicklung">
    <progress max="100" value="0"></progress>
    <section class="section section_01 section__bg-white">
      <div class="container">
        <div class="content-wrapper">
          <h1>Unruhige Beschaffungsmärkte</h1>
          <h3>Unsicherheiten an den Energiemärkten</h3>
          <p>
            Im letzten Quartal 2021 war der Energiemarkt von großer Dynamik
            geprägt. Die Beschaffungspreise lagen zu dieser Zeit bereits auf
            einem sehr hohen Niveau. Mit Beginn des Kriegs in der Ukraine hat
            diese Dynamik noch einmal deutlich zugenommen. Die Ursachen für
            beides – die Volatilität der Preise und deren hohes Niveau – sind
            vielseitig. Die Unsicherheit im Zusammenhang mit dem Krieg in der
            Ukraine stellen jedoch normale Marktfaktoren wie Nachfrage und
            Angebot in den Hintergrund. Vor diesem Hintergrund lassen sich seit
            Ende 2021 keine verlässlichen Prognosen zur Entwicklung der Preise
            an den Märkten mehr aufstellen.
          </p>
          <p>
            Die Charts verdeutlichen die aktuelle Entwicklung des Strom- und
            Gaspreises mit der Datenbasis des Frontjahres 2023. Der Strompreis
            hat sich im Vergleich zum Vorjahr mehr als vervierfacht. Der
            Gaspreis liegt sogar um zirka das 5,5-fache höher als im Juni 2021
            (Stand: 24. Juni 2022).
          </p>
          <h3>Verlässlicher Grundversorger</h3>
          <p>
            Wie wichtig die Verlässlichkeit auf die Stromgrundversorger, wie die
            EnBW ODR, ist, zeigte sich am Jahresende 2021. Die Einstellung der
            Stromlieferung durch zahlreiche Discount-Anbieter ließ die
            Kundenanzahl der ODR von einem Tag zum anderen um tausende Kunden
            ansteigen. Als regionaler Partner versorgen wir diese Kunden seither
            zuverlässig. Nichts desto trotz hat dieser kurzfristig enorme
            Kundenzuwachs auch unsere Energiebeschaffung deutlich belastet.
          </p>
          <div class="main-bubble text-center">
            <div class="bubble bubble__round-white">
              <h4>ODR bleibt</h4>
              <h3>verlässlicher</h3>
              <h4>Partner</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section_02 section__bg-grey">
      <div class="container">
        <div class="content-wrapper">
          <h2>Preisentwicklung Strom- und Gasbörse</h2>
          <b-row class="mt-5" cols="1" cols-md="2">
            <b-col class="p-3">
              <img
                src="../assets/images/preisentwicklung/chart_01.jpg"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col class="p-3">
              <img
                src="../assets/images/preisentwicklung/chart_02.jpg"
                alt=""
                srcset=""
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/mission" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/menschundnatur" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Preisentwicklung",

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } });
      tl.from("#ard_r, #ard_l", { delay: 0, y: -50 });
      tl.from("#ven", { delay: 0.5, scaleY: 0, transformOrigin: "bottom" });
      tl.from("#mann", { delay: 0.5, y: -100 }, "=.1");

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const animCharts = gsap.utils.toArray(".anim-chart");
      animCharts.forEach((animChart) => {
        gsap.from(animChart, {
          scale: 0,
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: animChart,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/preisentwicklung.scss";
</style>
