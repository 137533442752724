<template>
  <div class="page netzeodr">
    <progress max="100" value="0"></progress>
    <section class="section section__bg-white section__vw section__vh">
      <div class="main-banner jarallax">
        <img
          src="../assets/images/netzodr/banner.jpg"
          alt=""
          class="jarallax-img"
        />
        <div class="banner-content">
          <div class="container">
            <h2>
              Netze ODR GmbH –<br />
              Investitionen in die Versorgungsqualität
            </h2>
          </div>
        </div>
        <div class="logo-netzodr">
          <img src="../assets/images/netzodr/logo_netzeODR.png" alt="" />
        </div>
      </div>
      <div class="container container__pw">
        <div class="content-wrapper">
          <h3>Wir sichern die Energiewende</h3>
          <p>
            Leistungsfähige, intelligente Netzstrukturen sichern das Gelingen
            der Energiewende. Um diese Aufgabe bei fortschreitendem Ausbau der
            erneuerbaren Energien meistern zu können, haben wir im Berichtsjahr
            die EnBW ODR AG neu strukturiert. In diesem Zug haben wir die
            Netzgesellschaft gestärkt und die Netze ODR GmbH zu einer großen
            Netzgesellschaft ausgeprägt. Jetzt sind über 300 Mitarbeiter und
            alle Themen und Aufgaben der Strom- und Gas-Infrastruktur in ihr
            gebündelt.
          </p>
          <div class="main-bubble">
            <div class="bubble bubble__round-white">
              <h4>Investitionen</h4>
              <h3><span class="counter" data-number="35">35</span> Mio. €</h3>
              <p>
                in das Strom-<br />
                und Gasnetz
              </p>
            </div>
            <div class="bubble bubble__round-white">
              <h4>in 2021</h4>
              <h3>
                <span class="counter" data-number="13.100">13.100</span> km
              </h3>
              <p>
                Ausbau von<br />
                Stromtrassen
              </p>
            </div>
            <div class="bubble bubble__round-white">
              <h4>in 2021</h4>
              <h3>
                <span class="counter" data-number="1.968">1.968</span> GWh
              </h3>
              <p>
                Strom aus erneuer-<br />
                baren Energien
              </p>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="main-blog">
            <div class="blog-item">
              <div class="blog-img">
                <img
                  src="../assets/images/netzodr/img_blog_01.jpg"
                  alt=""
                  srcset=""
                />
              </div>
              <div class="blog-text">
                <h2>Technisch innovativ –</h2>
                <h3>
                  Energiewende erfordert Verdopplung des Investitionsvolumens
                </h3>
                <p>
                  Insgesamt 35 Millionen Euro hat unsere Netzgesellschaft im
                  Jahr 2021 in die Infrastruktur ihrer Strom- und
                  Gasverteilnetze investiert. Der massive Zubau an Anlagen, die
                  dezentral aus Sonne und Wind Strom erzeugen, erfordert hohe
                  Investitionen in innovative Steuerungstechnik und den
                  Leitungsausbau. Insgesamt kümmern wir uns um 13.100 Kilometer
                  Stromtrassen, 21 Umspannwerke und 102 Schaltanlagen sowie 66
                  Schaltwerke. Wir sind der größte Verteilnetzbetreiber in der
                  Region.
                </p>
                <p>
                  <strong>
                    Überzeugend sicher – unsere Versorgungsqualität ist
                    überdurchschnittlich hoch<br />
                  </strong>
                  Wie überdurchschnittlich zuverlässig die Versorgungsqualität
                  im Gebiet ist, zeigt der sogenannte SAIDI-Wert (System Average
                  Interruption Duration Index), den die Bundesnetzagentur jedes
                  Jahr veröffentlicht. Er gibt die durchschnittliche
                  Stromausfalldauer je versorgtem Verbraucher an: Im Netzgebiet
                  der Netze ODR fiel der Strom im vergangenen Jahr gerade mal
                  9,7 Minuten je Anschluss aus. Zum Vergleich: Im bundesweiten
                  Durchschnitt aller Stromnetze waren es 10,7 Minuten.
                </p>
                <p>
                  <img
                    src="../assets/images/netzodr/table-01.jpg"
                    alt=""
                    srcset=""
                    class="mt-3"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="content-wrapper">
          <h2>Schneller unabhängig -</h2>
          <h3>Neue Politik beschleunigt Aufwind für erneuerbare Energien</h3>
          <p>
            Die Energiewende ist im Netze ODR im bundesweiten Vergleich weit
            fortgeschritten: Im Jahr 2021 wurden schon 64 Prozent des
            Strombedarfs aus Sonne, Wind und Biogas erzeugt. Am Jahresende
            speisten rund 33.000 Anlagen mit einer installierten Gesamtleistung
            von 1,02 Gigawatt 1.968 Gigawattstunden Strom aus erneuerbaren
            Energien in das Verteilnetz ein. Wir rechnen mit einem Zubau von
            weiteren 16.000 Anlagen bis 2031: erfreulich für den Klimaschutz und
            angestrebte Energieautarkie.
          </p>
        </div>
      </div>
    </section>
    <section class="section section_04 section__bg-grey">
      <div class="container content-wrapper">
        <div class="main-blog">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="../assets/images/netzodr/img_blog_02.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="blog-text">
              <h2>Erneut besiegelt –</h2>
              <h3>Unsere Kommunen vertrauen auf uns</h3>
              <p>
                Mit den Städten und Gemeinden verbindet die EnBW ODR und Netze
                ODR seit vielen Jahren eine vertrauensvolle Partnerschaft. Umso
                mehr freuen wir uns, dass unsere Netze ODR bei der
                Konzessionsvergabe im Berichtsjahr mit ihren Leistungen erneut
                überzeugte: 10 Kommunen haben Ihre Konzessionen für Strom und
                Gas erneut für weitere 20 Jahre mit uns abgeschlossen.
              </p>
              <p>
                <strong>
                  Gemeinsam stärker – Wir bieten Kommunen nützliche Mehrwerte<br />
                </strong>
                Wir verstehen uns sowohl als EnBW ODR wie als Netze ODR
                mitverantwortlich für die Entwicklung unserer
                Konzessionsgemeinden. Gemeinsam mit ihnen entwickeln wir
                Lösungen für eine klimaneutrale Energiezukunft der Kommunen.
                Dabei gehen wir auch mutig neue Wege, wie in Bergheim beim
                Projekt Duopflugverfahren. „Lokale und regionale Konzepte sind
                der Schlüssel, wenn es darum geht, aktiv Ressourcen zu schonen
                und die nationalen Klimaschutzziele zu erreichen“, erklärt
                Matthias Steiner, Geschäftsführer der Netze ODR GmbH.
              </p>
              <p class="text-bleu">
                * Seit Oktober 2021 schließt die neue Netzgesellschaft Netze ODR
                als Tochterunternehmen der EnBW ODR alle Konzessionen ab.
              </p>
              <b-container fluid class="text-center mt-5">
                <b-row cols="1" cols-xl="2" align-h="between">
                  <b-col class="p-3" md="5">
                    <img
                      src="../assets/images/netzodr/regions.jpg"
                      alt=""
                      srcset=""
                    />
                  </b-col>
                  <b-col class="p-3" md="5">
                    <div class="main-bubble m-0">
                      <div class="bubble bubble__round-white regio">
                        <h3>
                          regionale<br />
                          Konzepte
                        </h3>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/emobilitaet" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/engagement" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Netzeodr",
  //components: { BannerSVG },

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    playAnimation() {
      $(".counter").each(function () {
        var count = $(this),
          zero = { val: 0 };
        gsap.to(zero, {
          duration: 2,
          val: count.data("number"),
          onUpdate: countNumber,
        });
        function countNumber() {
          var final = gsap.utils.snap(2, zero.val);
          count.text(final);
        }
      });
    },
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      /*  const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } });
      tl.from("#ard_r, #ard_l", { delay: 0, y: -50 });
      tl.from("#ven", { delay: 0.5, scaleY: 0, transformOrigin: "bottom" });
      tl.from("#mann", { delay: 0.5, y: -100 }, "=.1"); */

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const itemBlogs = gsap.utils.toArray(".blog-item");
      itemBlogs.forEach((itemBlog) => {
        const imgBlog = itemBlog.querySelectorAll(".blog-img");
        const textBlog = itemBlog.querySelectorAll(".blog-text");
        gsap.from(imgBlog, {
          scale: 0.5,
          opacity: 0,
          duration: 1.2,
          scrollTrigger: {
            trigger: itemBlog,
            toggleActions: "play none none none",
          },
        });
        gsap.from(textBlog, {
          opacity: 0,
          duration: 1.2,
          scrollTrigger: {
            trigger: itemBlog,
            toggleActions: "play none none none",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
          //onEnter: this.playAnimation(),
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/netzeodr.scss";
</style>
