<template>
  <div class="btn-menu">
    <a @click="showMenu">
      <span class="bars"></span>
      Menu
    </a>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "BurgerMenu",
  methods: {
    showMenu: () => {
      $("#app").toggleClass("show");
    },
  },
};
</script>
