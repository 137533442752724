<template>
  <div class="page mission">
    <progress max="100" value="0"></progress>
    <section class="section section_01 section__bg-grey section__vh">
      <div class="container">
        <div class="content-wrapper">
          <h1>Mission</h1>
          <p>
            2021 war auch sehr stark geprägt von sehr stark steigenden Preisen
            an den Großhandelsmärkten für Strom und Gas mit Folgewirkungen für
            alle Marktteilnehmer. Unter diesen Rahmenbedingungen haben wir die
            Unternehmensstruktur neu geordnet und die Netze ODR (vormals NGO) zu
            einer großen Netzgesellschaft ausgeprägt. In diesem Zug haben wir
            unsere Netztochter gestärkt und in Netze ODR umbenannt. Auch haben
            wir mit dem Projekt sCORE die Digitalisierung aller Prozesse weiter
            vorangebracht; eine wesentliche Voraussetzung für
            Wettbewerbsfähigkeit und Effizienz. Unsere Unternehmenspolitik
            richten wir konsequent an Nachhaltigkeit aus. Das halten wir für
            unabdingbar, um in den Dimensionen Ökologie, Soziales und
            Wirtschaftlichkeit produkt- und leistungsseitig nachhaltig zu
            wirtschaften. Für unser aller Lebensqualität.
          </p>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/aktionaersbrief" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/preisentwicklung" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Mission",

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } });
      tl.from("#ard_r, #ard_l", { delay: 0, y: -50 });
      tl.from("#ven", { delay: 0.5, scaleY: 0, transformOrigin: "bottom" });
      tl.from("#mann", { delay: 0.5, y: -100 }, "=.1");

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const animCharts = gsap.utils.toArray(".anim-chart");
      animCharts.forEach((animChart) => {
        gsap.from(animChart, {
          scale: 0,
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: animChart,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/engagement.scss";
</style>
