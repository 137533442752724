<template>
  <div class="page">
    <progress max="100" value="0"></progress>
    <section class="content-table">
      <div class="container">
        <h2 class="headline">
          Gewinn- und Verlustrechnung für die Zeit<br />
          vom 1. Januar bis 31. Dezember 2021
        </h2>
        <div class="mainflex header border-2 border-top">
          <div class="item iteam-2s bold">in T€</div>
          <div class="item bold new">2021</div>
          <div class="item bold">Vorjahr</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">1. Umsatzerlöse</div>
          <div class="item new">411.379</div>
          <div class="item">458.838</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">2. Andere aktivierte Eigenleistungen</div>
          <div class="item new">114</div>
          <div class="item">2.846</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">3. Sonstige betriebliche Erträge</div>
          <div class="item new">1.490</div>
          <div class="item">3.182</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s"></div>
          <div class="item new">412.983</div>
          <div class="item">464.867</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s bold"></div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">4. Materialaufwand</div>
          <div class="item new">- 382.873</div>
          <div class="item">- 345.795</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">5. Personalaufwand</div>
          <div class="item new">- 19.341</div>
          <div class="item">- 43.207</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">
            6. Abschreibungen auf immaterielle Vermögensgegenstände des
            Anlagevermögens und Sachanlagen
          </div>
          <div class="item new">- 2.119</div>
          <div class="item">- 23.993</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">7. Sonstige betriebliche Aufwendungen</div>
          <div class="item new">- 10.702</div>
          <div class="item">- 16.503</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s bold">
            8. Ergebnis der betrieblichen Tätigkeit
          </div>
          <div class="item new bold">- 2.052</div>
          <div class="item bold">35.368</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">9. Finanzergebnis</div>
          <div class="item new">- 16.967</div>
          <div class="item">- 25.314</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">
            10. Steuern vom Einkommen und vom Ertrag
          </div>
          <div class="item new">4.319</div>
          <div class="item">- 6.289</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s bold">11. Ergebnis nach Steuern</div>
          <div class="item new bold">- 14.700</div>
          <div class="item bold">3.765</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">12. Sonstige Steuern</div>
          <div class="item new">- 5</div>
          <div class="item">- 128</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">13. Aufwendungen aus Gewinnabführung</div>
          <div class="item new">14.705</div>
          <div class="item">- 3.637</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s bold">14. Jahresüberschuss</div>
          <div class="item new bold">0</div>
          <div class="item bold">0</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">15. Gewinnvortrag aus dem Vorjahr</div>
          <div class="item new">24</div>
          <div class="item">24</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s bold">16. Bilanzgewinn</div>
          <div class="item new bold">24</div>
          <div class="item bold">24</div>
        </div>
      </div>
      <!-- End Content -->
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/bilanz" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <!-- <router-link to="/gewinn" tag="div"><a class="link link__next"></a></router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Gewinn",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/bilanz.scss";
</style>
