import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { gsap } from "gsap";
/* import VueNumber from "vue-number-animation"; */
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import { TimelineLite } from 'gsap'
gsap.registerPlugin(ScrollTrigger);

/* import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; */
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "normalize.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
/* Vue.use(VueNumber) */

import VideoBackground from "vue-responsive-video-background-player";
Vue.component("video-background", VideoBackground);

import "./scss/main.scss";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
