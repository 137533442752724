<template>
  <div class="page menschundnatur">
    <progress max="100" value="0"></progress>
    <section class="section section__bg-white section__vw">
      <div class="main-banner jarallax">
        <img
          src="../assets/images/menschundnatur/banner.jpg"
          alt=""
          class="jarallax-img"
        />
        <div class="banner-content">
          <div class="container">
            <h2>Respekt vor Mensch und Natur</h2>
            <h3>Wir denken und handeln nachhaltig</h3>
          </div>
        </div>
        <div class="main-bubble">
          <div class="bubble bubble__round-white">
            <h4>€/Jahr</h4>
            <h3>35 Mio.</h3>
            <p>
              Investment der Netze ODR in<br />
              Lebensadern der Wirtschaft –<br />
              unsere Energienetze
            </p>
          </div>
        </div>
      </div>
      <div class="container container__pw">
        <div class="content-wrapper">
          <p>
            Als Energieunternehmen tragen wir eine besondere Mitverantwortung
            für die Gestaltung einer nachhaltigen Versorgung und eines
            Lebensumfelds, das auf die natürliche Regenerierung der Systeme Acht
            gibt: aus Gründen der Lebensqualität, der Generationengerechtigkeit
            und des sozialen Zusammenhalts.
          </p>
          <h3>Nachhaltig voran – gemeinsam für eine klimaneutrale Zukunft</h3>
          <p>
            Der Klimaschutz ist eine wichtige Säule für Nachhaltigkeit und
            wesentlich für die künftige Lebensqualität auf unserem Planeten. Die
            EnBW ODR soll deshalb schon bis 2023 klimaneutral sein. Dieses Ziel
            möchten wir gemeinsam mit Konzessionsgemeinden, Geschäftspartnern
            und Kunden erreichen. Neben umwelt- und energiebewusster
            Unternehmensführung fördern wir deshalb die ganzheitliche
            Energiewende und unterstützen unser Umfeld mit innovativen
            energienahen Dienstleistungen.
          </p>
        </div>
      </div>
    </section>

    <section class="section section__bg-grey">
      <div class="">
        <b-tabs content-class="mt-3" align="center">
          <!-- Tab 1: Zertifizierung -->
          <b-tab title="Zertifizierung" active>
            <div class="container">
              <div class="main-blog">
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_zertifizierung_01.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Rundum transparent –</h2>
                    <h3>Zertifizierung belegt unser nachhaltiges Handeln</h3>
                    <p>
                      „Bei uns, der EnBW ODR, ist bei allen Entscheidungen
                      Nachhaltigkeit die oberste Prämisse – in ökologischer,
                      ökonomischer und sozialer Hinsicht“, sagt Sebastian Maier,
                      technischer Vorstand der ODR. Wir wollen die natürliche
                      Regenerationsfähigkeit aller beteiligten Systeme dauerhaft
                      gewährleisten und dies belegen. So hat unser
                      Umweltmanagementsystem im Jahr 2021 den
                      Zertifizierungsprozess für EMAS (Eco-Management and Audit
                      Scheme der Europäischen Union) durchlaufen und bestanden.
                    </p>
                    <p>
                      <strong>
                        Immer besser – EMAS ist ein interner Innovationstreiber
                      </strong>
                      <br />
                      „Wir wollen unsere eigene Umweltleistung in jeder
                      Beziehung nicht nur transparent machen, sondern vor allem
                      immer besser werden. Deshalb sind bei uns alle
                      Mitarbeitenden als Experten ihres Arbeitsbereichs in
                      Maßnahmen für den Umweltschutz und die Energieeffizienz
                      einbezogen“. Alle Prozesse kommen regelmäßig auf den
                      Prüfstand und werden im Sinne der Ressourceneffizienz
                      optimiert. „So gesehen ist EMAS für uns auch ein wichtiger
                      internen Innovationstreiber“, betont Sebastian Maier.
                    </p>
                    <b-container fluid="lg" class="bubble-blog">
                      <b-row>
                        <b-col>
                          <div class="main-bubble">
                            <div class="bubble bubble__round-white">
                              <h4>EMAS</h4>
                              <h3>ISO 50001</h3>
                              <p>
                                weltweit gültige Norm für<br />Energiemanagementsysteme
                              </p>
                            </div>
                          </div>
                        </b-col>
                        <b-col>
                          <div class="main-bubble">
                            <div class="bubble bubble__round-white">
                              <h4>EMAS</h4>
                              <h3>ISO 14001</h3>
                              <p>Umweltmanagementnorm</p>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_zertifizierung_02.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Erneut ausgezeichnet –</h2>
                    <h3>Wir sind wieder Top-Lokalversorger</h3>
                    <p>
                      2021 wurden wir zum fünften Mal für unsere Leistungen im
                      Rahmen der Strom- und Gasversorgung als TOP-Lokalversorger
                      ausgezeichnet. Das Gütesiegel vergibt das
                      Energieverbraucherportal jedes Jahr an Energieunternehmen
                      mit regionaler Ausrichtung, die mit ihrem Angebot
                      besonders verbraucherfreundlich, serviceorientiert und
                      innovativ sind. Dazu zählen auch Transparenz, regionales
                      Engagement und Nachhaltigkeit. Gegenüber 2020 haben wir
                      uns vor allem im Service weiter verbessert.
                    </p>
                    <p>
                      <strong>
                        Genial regional – Broschüre überbrückt Ausfall von
                        Veranstaltungen
                      </strong>
                      Wir leben Kundennähe: Doch auch im Berichtsjahr waren die
                      liebten Messen und Veranstaltungen kaum möglich – die
                      Maßnahmen zur Eindämmung des Infektionsgeschehens ließen
                      sie nicht zu. Um unsere Kundinnen und Kunden dennoch nah
                      zu sein, haben wir die Broschüre Genial Regional ins Leben
                      gerufen und sie mit Angeboten gefüllt. Ausgegeben wurde
                      sie in Bäckereien wie Mack, Stollenmeier und Diethei sowie
                      in den Filialen der Kreissparkasse Ostalb. „Der direkte
                      Austausch mit unseren Kunden auf Messen und
                      Veranstaltungen hat uns auch 2021 gefehlt. Mit Genial
                      Regional wollten wir diese Lücke füllen“, so Moritz Feil,
                      Teamleiter Vertriebsmanagement Privatkunden und
                      Kleingewerbe.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <!-- Tab2: Klimaschutz -->
          <b-tab title="Klimaschutz">
            <div class="container">
              <div class="main-blog">
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_klimaschutz_01.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Effizient reduzieren –</h2>
                    <h3>
                      Mit ODR CO<sub>2</sub>-Pilot aktiv Treibhausgase einsparen
                    </h3>
                    <p>
                      Klimaschutz gelingt nur gemeinsam: Unseren Industriekunden
                      bieten wir deshalb jetzt auch die Möglichkeit an, mit
                      Hilfe unseres CO<sub>2</sub>-Piloten ihre Emissionen an
                      Treibhausgasen zu bilanzieren. Über diesen Weg bekommen
                      sie auch aufgezeigt, wo und wie sie ihren
                      CO<sub>2</sub>-Ausstoß wirksam reduzieren können. Die
                      verbleibenden Restemissionen können sie über zertifizierte
                      Klimaschutzprojekte unseres Partners myclimate ganz
                      einfach kompensieren.
                    </p>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_klimaschutz_02.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Digital spart –</h2>
                    <h3>Weniger Papier belohnen wir mit mehr Bäumen</h3>
                    <p>
                      Stellen unsere Kunden von gedruckten Rechnungen auf
                      Online-Versionen um, helfen sie der Umwelt. Diesen Beitrag
                      zum Ressourcenschutz belohnen wir, indem wir mit der
                      Schutzgemeinschaft Deutscher Wald e. V. für jede
                      Umstellung einen Baum in der Region pflanzen; allein im
                      Jahr 2021 waren es 2.800. Seit Beginn des Projekts im Jahr
                      2016 sind auf diese Weise mehr als 10.000 Bäume im
                      Versorgungsgebiet hinzugekommen. Digitaler Service dient
                      dem Klimaschutz; wir bauen ihn konsequent aus.
                    </p>
                    <b-container fluid="lg" class="bubble-blog">
                      <b-row>
                        <b-col>
                          <div class="main-bubble">
                            <div class="bubble bubble__round-white">
                              <h4>Mehr als</h4>
                              <h3>10.000</h3>
                              <p>Bäume für Onlinerechnungen gepflanzt</p>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_klimaschutz_03.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Mitmachen belohnt –</h2>
                    <h3>12.000 Euro gibt es für Umwelthelden</h3>
                    <p>
                      <img
                        class="img-fr"
                        src="../assets/images/menschundnatur/odr_umwelthelden.png"
                        alt=""
                      />
                      Neu ins Leben gerufen haben wir im Berichtsjahr den
                      Förderwettbewerb „ODR-Umwelthelden“. Mitmachen können
                      gemeinnützige Vereine mit Sitz in unserem Geschäftsgebiet,
                      die sich für Klimaschutz und Nachhaltigkeit in der Region
                      einsetzen. 39 Vereine nahmen teil. Das Besondere am
                      Wettbewerb: Jeder darf mitentscheiden und für sein
                      Herzensprojekt stimmen. Der mit 12.000 Euro dotierte Preis
                      ging an den Förderverein Grundschule Täferrot e. V. mit
                      seinem Projekt „Bepflanzung neuer Bäume am
                      Schulbauernhof“.
                    </p>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_klimaschutz_04.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Natürlich schützen –</h2>
                    <h3>Insektenhotels fördern Artenvielfalt</h3>
                    <p>
                      Der Lebensraum von Insekten, die als Bestäuber und Nahrung
                      anderer Tierarten dringend gebraucht werden, wird
                      zunehmend eingeschränkt. Mit unseren Insektenhotels wollen
                      wir Menschen für die Bedeutung der Insekten
                      sensibilisieren. „Allein im Berichtsjahr haben wir 77
                      Bausätze für Insektenhotels unter Kindergärten und
                      Grundschulen verlost. Ein Beitrag, das Gleichgewicht in
                      der Natur wiederherzustellen“, erklärt Stefanie
                      Stengel-Mack aus dem kommunalen Management der ODR.
                    </p>
                    <p>
                      <strong>
                        Spielerisch lernen – Energie und Klima kommen mit der
                        Box<br />
                      </strong>
                      27 spannende Versuche und Phänomene rund um Klimawandel,
                      Klimaschutz und erneuerbare Energien kommen mit der
                      EnBW-Klima- und Energiebox an Kindergärten und Schulen.
                      Spielerisch lernen die jungen Entdecker beim
                      Experimentieren so naturwissenschaftliche Zusammenhänge
                      kennen und sammeln Wissen. Dabei entwickeln sie ein
                      Bewusstsein für Energie und Klimaschutz. 33 Energieboxen
                      haben wir 2021 an Grundschulen und Kindergärten im
                      Versorgungsgebiet verlost.
                    </p>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_klimaschutz_05.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Nachhaltig unterwegs</h2>
                    <h3>
                      Elektromobilität als wichtiger Baustein der Energiewende
                    </h3>
                    <p>
                      Wenn wir in Deutschland unsere Klimaziele erreichen
                      wollen, müssen wir die Energiewende ganzheitlich angehen
                      und das Tempo erhöhen. Die Ablösung von
                      Verbrennungsmotoren im Verkehr ist ein wesentlicher
                      Baustein der Energiewende. Im eigenen Haus gehen wir mit
                      gutem Beispiel voran: Unsere Mitarbeitenden können über
                      uns ein E-Auto leasen und ihre Elektrofahrzeuge am
                      E-Ladepark der EnBW ODR mit grünem Strom laden.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <!-- Digitalisierung -->
          <b-tab title="Digitalisierung">
            <div class="container">
              <div class="main-blog">
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_digitalisierung_01.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Zukunft durch Digitalisierung</h2>
                    <h3>Wir nutzen die Chancen für Weiterentwicklung</h3>
                    <p>
                      Wir haben große Fortschritte gemacht in der Transformation
                      der EnBW ODR zu einem Unternehmen, das langfristig
                      profitabel und der Region und ihrer Kundschaft nützlich
                      sein wird. Das zeigen die Erfolge des Berichtsjahrs. Wir
                      haben durch Digitalisierung die interne Effizienz
                      gesteigert, die Versorgungs- und Datensicherheit weiter
                      erhöht und für unsere Kunden smarte digitale
                      Dienstleistungen entwickelt.
                    </p>
                    <h2>Smart transformiert –</h2>
                    <h3>EnBW Konzern digitalisiert komplette Prozesswelt</h3>
                    <p>
                      Der EnBW-Konzern nutzt die Chancen der Digitalisierung und
                      transformiert seine komplette Prozesswelt: sCore25, so
                      heißt das Projekt, steht für „smart Core“ – intelligenter
                      Kern – und Strategie 2025. In dessen Rahmen bringen auch
                      wir als ODR alle Prozesse, die für die Führung eines
                      Unternehmens erforderlich sind, auf eine gemeinsame
                      ERP-Plattform (Enterprise Resource Planning). Das
                      erfordert eine radikale Vereinfachung und
                      End-to-End-Betrachtung aller Prozesse.
                    </p>
                    <p>
                      <strong>
                        Optimal steuerbar – intelligentes Datenmanagement macht
                        sicher und frei
                      </strong>
                    </p>
                    <p>
                      Durch die konsequente Transformation und Nutzung einer
                      gemeinsamen Plattform werden konzernweit fachliche und
                      technische Mindeststandards geschaffen und Freiheitsgrade
                      definiert – ob bei Finanzen, Einkauf oder Instandhaltung
                      und Bauabwicklung. Dadurch werden nicht nur Synergien
                      gehoben und die Effizienz erhöht; das intelligente
                      Datenmanagement einer geschäftsübergreifenden
                      IT-Landschaft schafft auch die für eine optimale
                      Unternehmenssteuerung erforderliche Transparenz.
                    </p>
                    <div class="bloc-text">
                      <h3>
                        <strong>sCore25</strong>
                      </h3>
                      <p>
                        „In dieses digitale Großprojekt sind viele
                        EnBW-Beteiligungen und deren Mitarbeiter aktiv
                        eingebunden. So ist gewährleistet, dass die entwickelten
                        Standards für alle passen. Eine gelungene
                        gesellschaftsübergreifende Zusammenarbeit auf Augenhöhe
                        mit dem EnBW Konzern war die Basis für den Erfolg des
                        Projekts“,
                        <strong>Stephan Maas, Projektleiter sCore25.</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_digitalisierung_03.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Erfolgreiches ISMS-Audit –</h2>
                    <h3>
                      Geschäftsdaten und Systeme vor Cyberangriffen gut
                      geschützt
                    </h3>
                    <p>
                      Im Berichtsjahr haben wir als ODR mitsamt unserer
                      Netzgesellschaft das Überwachungsaudit für das
                      Informationssicherheitsmanagementsystem (ISMS) erfolgreich
                      absolviert. Die Zertifikate für die Einhaltung der DIN EN
                      ISO/IEC 27001:2017 und des IT-Sicherheitskatalogs gemäß
                      §11 Abs. 1a EnWG (08/2025) sind für ein weiteres Jahr
                      bestätigt. In Zeiten zunehmender Cyber-Bedrohungen auf
                      kritische Infrastrukturen ist uns dies besonders wichtig.
                      Auditiert wurden: Rechenzentren, Leitstelle, IT,
                      Netzbetrieb, unbemannte Standorte (Gasdruckregelstationen,
                      Gasdruckmessanlagen, Umspannstationen, Umspannwerke,
                      Schaltwerke), Einkauf und Personal und weitere
                      Fachbereiche.
                    </p>
                    <div class="bloc-zitat">
                      <p>
                        „Durch konsequentes Hochfahren unserer
                        Sicherheitsstandards konnten wir trotz höherer
                        Bedrohungslage das Risikopotenzial auf niedrigem Niveau
                        halten“, so
                        <strong>
                          Patrick Weber, Teamleiter IT und
                          Geo-Informationsdienste
                        </strong>
                      </p>
                    </div>
                    <h2>Digital beraten –</h2>
                    <h3>Chatbot FRIDA bekommt von Kunden gute Noten</h3>
                    <p>
                      <img
                        class="img-fr"
                        src="../assets/images/menschundnatur/Frida.jpg"
                        alt=""
                        srcset=""
                      />
                      Unsere digitale Service-Assistentin FRIDA ist Privat- und
                      Geschäftskunden bereits eine große Hilfe und wird von
                      ihnen sehr geschätzt. „Im vergangenen Jahr hat sie über
                      10.000 Anfragen zu Abschlagsänderungen sowie Produkten und
                      Zählerständen beantwortet“, sagt Manuela Schön aus dem
                      Kundenservice, die FRIDA ins Leben gerufen hat. „Wir
                      erweitern ihr Repertoire deshalb stetig“. Neu sind seit
                      2021 zum Beispiel Erklärvideos zum Energiemarkt und
                      Beratungsangebote für Industriekunden.
                    </p>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img">
                    <img
                      src="../assets/images/menschundnatur/img_digitalisierung_02.jpg"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="blog-text">
                    <h2>Online abrufbar –</h2>
                    <h3>Innovatives Geodatenportal ist mehr als INSPIRE</h3>
                    <p>
                      Gemeinsam mit dem Ostalbkreis haben wir für den Landkreis
                      die EU-Richtlinie INSPIRE (INfrastructure for SPatial
                      InfoRmation in Europe) umgesetzt. Diese verpflichtet
                      Kommunen seit 2021, Geodaten einheitlich digital zu
                      präsentieren. Wir sind einen Schritt weiter gegangen und
                      haben ein Geoportal entwickelt, das die digitalen
                      Bauleitpläne mit der bestehenden Ostalbmap verknüpft:
                      Unter www.ostalbmap.de kann jetzt jeder einsehen, welche
                      Grundstücke bebaut werden dürfen und in welcher Form.
                    </p>
                    <div class="bloc-zitat">
                      <p>
                        „Unser Geo-Informationsportal ODR-GeoPortal entlastet
                        Städte und Gemeinden erheblich, weil alle Informationen
                        zu Bebauungsplänen online einfach und schnell abrufbar
                        sind - auf jedem Endgerät. Das gibt Planungs- und
                        Investitionssicherheit für Investoren, Architekten und
                        Bauinteressenten.“
                        <strong>
                          Patrick Weber, Teamleiter IT und
                          Geo-Informationsdienste
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="blog-item">
                  <div class="blog-img"></div>
                  <div class="blog-text">
                    <h2>Digital vernetzt –</h2>
                    <h3>Auch auf sozialen Medien pflegen wir den Dialog</h3>
                    <p>
                      <img
                        class="iphone"
                        src="../assets/images/menschundnatur/iphone.png"
                        alt=""
                        srcset=""
                      />
                      „Unsere Social-Media-Profile auf Facebook und Instagram
                      nutzen wir für einen aktiven Dialog mit allen, die sich
                      für die ODR interessieren“, informiert Franziska Held,
                      Social Media Managerin. Wir berichten dort tagesaktuell
                      über Neues aus der EnBW ODR sowie der Energiebranche und
                      -politik. Unterhaltsames, Wettbewerbe, Umfragen und Tipps
                      – auch zum Klimaschutz – runden das Spektrum ab. Dass
                      diese Art des Dialogs gut ankommt, zeigt die Zahl unserer
                      Follower: rund 3.000 sind es bereits.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>

    <div class="main-nav-section">
      <div class="container">
        <router-link to="/preisentwicklung" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/emobilitaet" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Menschundnatur",
  components: {},
  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    playAnimation() {
      $(".counter").each(function () {
        var count = $(this),
          zero = { val: 0 };
        gsap.to(zero, {
          duration: 2,
          val: count.data("number"),
          onUpdate: countNumber,
        });
        function countNumber() {
          var final = gsap.utils.snap(1, zero.val);
          count.text(final);
        }
      });
    },
    scrollAnimation() {
      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });

      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const animCharts = gsap.utils.toArray(".anim-chart");
      animCharts.forEach((animChart) => {
        gsap.from(animChart, {
          scale: 0,
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: animChart,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const itemBlogs = gsap.utils.toArray(".blog-item");
      itemBlogs.forEach((itemBlog) => {
        const imgBlog = itemBlog.querySelectorAll(".blog-img");
        const textBlog = itemBlog.querySelectorAll(".blog-text");
        gsap.from(imgBlog, {
          scale: 0.5,
          opacity: 0,
          duration: 1.2,
          scrollTrigger: {
            trigger: itemBlog,
            toggleActions: "play none none none",
          },
        });
        gsap.from(textBlog, {
          opacity: 0,
          duration: 1.2,
          scrollTrigger: {
            trigger: itemBlog,
            toggleActions: "play none none none",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        y: -100,
        scale: 0,
        opacity: 0,
        duration: 1,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
          onEnter: this.playAnimation(),
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/menschundnatur.scss";
</style>
