<template>
  <div class="page emobilitaet">
    <progress max="100" value="0"></progress>
    <video-background
      :src="require(`@/assets/videos/Infrastrukturkabelpflug.mp4`)"
      style="max-height: 600px; min-height: 600px; height: calc(100vh - 350px)"
    >
      <section id="section-video">
        <h2>
          Infrastrukturen für<br />E-Mobilität und<br />schnelles Internet
        </h2>
      </section>
    </video-background>
    <section class="section section__bg-white">
      <div class="container container__pw">
        <div class="content-wrapper">
          <h2>Zukunftsfähig ausgebaut</h2>
          <h3>Turboschnelles Internet fördert Standortentwicklung</h3>
          <p>
            Mit dem Ausbau des Glasfasernetzes wollen wir die Digitalisierung
            auch im ländlichen Raum voranbringen, denn verlässliches und
            schnelles Internet ist ein wichtiger Teil attraktiver Lebens- und
            Wirtschaftsräume. Aus diesem Grund unterstützen wir, gemeinsam mit
            unserer Tochtergesellschaft Netze ODR, Kommunen beim Ausbau eines
            leistungsstarken Breitbandnetzes. Wir verstehen uns dabei als
            Partner der Kommunen, mit dessen Hilfe eine schnelle Realisierung
            möglich ist.
          </p>
          <p>
            <strong>
              Lichtschnell verkabelt – Wir sind Partner der Kommunen beim Ausbau
              von Glasfasernetzen
            </strong>
            Im Berichtsjahr 2021 sind zwei High-Speed-Projekte an den Start
            gegangen: in Stimpfach und Bopfingen. In Stimpfach werden 47
            Kilometer Glasfaserkabel verlegt und 25 Kilometer Trassen neu
            gebaut. Bis zum Jahr 2025 werden in der Kommunen über 8.000 Gebäude
            an das lichtschnelle Glasfasernetz angeschlossen sein. In Bopfingen
            sollen bis 2023 alle Bürgerinnen und Bürger über ein superschnelles
            Internet verfügen können. Beide Projekte werden öffentlich
            gefördert.
          </p>
          <h3>Wir schaffen Voraussetzungen für nachhaltige Lebensqualität</h3>
          <p>
            Ein dichtes komfortables Ladenetz für Elektrofahrzeuge und ein
            leistungsfähiges Glasfasernetz sind wichtige Voraussetzungen, um die
            Energiewende schnell und ganzheitlich voranzubringen. Mit unserem
            Wissen und unserer Erfahrung in Planung und Betrieb energetischer
            Infrastrukturen leisten wir hierzu einen Beitrag in unserer Region.
          </p>
        </div>
      </div>
    </section>
    <section class="section section_03 section__bg-white">
      <div class="jarallax jarallax_section">
        <img
          src="../assets/images/emobilitaet/parallax_section_02.jpg"
          alt=""
          class="jarallax-img"
        />
        <div class="jarallax_content">
          <div class="container">
            <div class="bloc-text bg-white shadow">
              <h3><strong>Mobilitätswende </strong></h3>
              <p>
                Durch unseren Einsatz für den Ausbau der Ladeinfrastruktur in
                unserer Region sind wir Innovationstreiber für die E-Mobilität
                in unserer Heimat.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section_02 section__bg-dark_grey">
      <div class="container">
        <div class="flex-content flex-content_x2 gap-large">
          <div class="item_content">
            <img
              src="../assets/images/emobilitaet/stationnen.png"
              alt=""
              srcset=""
            />
          </div>
          <div
            class="item_content flex-content flex-content__column flex-content__center-center"
          >
            <div class="main-bubble">
              <div class="bubble bubble__round-white">
                <h2 class="pt-4">Mehr als</h2>
                <h3>140</h3>
                <h2 class="text-center">
                  Ladestationen<br />
                  Ende 2021
                </h2>
              </div>
            </div>
            <div class="bloc-text bg-white shadow">
              <h3>
                <strong>Flächendeckend laden –</strong><br />
                Komfortables Ladenetz macht Umstieg leichter
              </h3>
              <p>
                Schon jetzt betreiben wir über 140 öffentliche Ladepunkte in der
                Region, im letzten Jahr sind 13 neue dazugekommen. Alle
                Ladestationen werden mit zertifiziertem Grünstrom beliefert.
                Damit haben wir eine flächendeckende und klimafreundliche
                Ladeinfrastruktur geschaffen, die wir noch weiter ausbauen. 22
                weitere Ladepunkte sind aktuell in Planung. So schaffen wir die
                Voraussetzungen für den Mobilitätswandel und den Wechsel von
                fossilen Energieträgern zu CO<sub>2</sub>-neutraler Mobilität.
              </p>
            </div>
          </div>
        </div>
        <div class="content main-gallery">
          <vueper-slides
            class="no-shadow"
            autoplay
            :visible-slides="3"
            :gap="5"
            :bullets="false"
            :slide-ratio="1 / 5"
            :infinite="false"
            :duration="4000"
            :dragging-distance="20000"
            :breakpoints="{
              1023: { visibleSlides: 3, slideRatio: 1 / 5 },
              800: { visibleSlides: 2, slideRatio: 1 / 3 },
              500: { visibleSlides: 1, slideRatio: 4 / 6 },
            }"
          >
            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
              :title="slide.title"
              :content="slide.content"
            />
          </vueper-slides>
        </div>
      </div>
    </section>
    <section class="section section_03 section__bg-white">
      <div class="jarallax jarallax_section">
        <img
          src="../assets/images/emobilitaet/parallax_section_03.jpg"
          alt=""
          class="jarallax-img"
        />
        <div class="jarallax_content">
          <div class="container">
            <div class="bloc-text bg-white shadow pos-r">
              <h3>
                <strong>
                  120 Ladepunkte<br />
                  bei ZEISS
                </strong>
              </h3>
              <p>
                Neue Photovoltaik-Anlage auf Parkhaus erhöht die installierte
                Leistung bei Zeiss in Oberkochen auf 1.350 KWp. 2022: Zeiss
                deckt seinen Bedarf weltweit mit 100% Ökostrom.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section_04 section__bg-grey">
      <div class="container content-wrapper">
        <h2 class="text-left">Einfach aufladen –</h2>
        <h3 class="text-left">Wir bieten bequeme Lösungen für überall</h3>
        <p>
          Unsere mobilityME App macht nicht nur das Laden von Ökostrom einfach,
          sondern auch das Finden naheliegender Ladestationen. Denn sie listet
          übersichtlich rund 230.000 Ladepunkte in ganz Europa auf. Damit ist
          man auch auf Reisen sicher mobil. Die mobilityME App nutzen inzwischen
          1.600 E-Mobilisten.
        </p>
        <p>
          <strong>
            Zusammen schneller – mit ZEISS machen wir den Alltag grüner
          </strong>
        </p>
        <p>
          Gemeinsam mit ZEISS haben wir einen der größten Ladeparks Deutschlands
          in Betrieb genommen. Seit 2021 können ZEISS Mitarbeitende ihre
          Elektroautos an 120 neuen Ladepunkten in Oberkochen aufladen. „Wir
          sind Innovationstreiber für die Region Ostwürttemberg und tragen mit
          dem Ausbau der Ladeinfrastruktur zur Mobilitätswende bei.“, bekräftigt
          Johannes Gresser, Teamleiter E-Mobilität, die Zusammenarbeit mit
          ZEISS.
        </p>
        <p class="text-center">
          <img src="../assets/images/emobilitaet/karte.png" alt="" srcset="" />
        </p>
        <div class="mt-5">
          <b-row cols="1" cols-md="3">
            <b-col class="p-3">
              <img
                src="../assets/images/emobilitaet/station_03.jpg"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col class="p-3">
              <img
                src="../assets/images/emobilitaet/station_04.jpg"
                alt=""
                srcset=""
              />
            </b-col>
            <b-col class="p-3">
              <img
                src="../assets/images/emobilitaet/station_05.jpg"
                alt=""
                srcset=""
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/menschundnatur" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/netzeodr" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Emobilitaet",
  components: { VueperSlides, VueperSlide },

  data: () => ({
    slides: [
      {
        title: "Aufhausen",
        image: require("@/assets/images/emobilitaet/gallery/img_Aufhausen.jpg"),
      },
      {
        title: "Möttingen",
        image: require("@/assets/images/emobilitaet/gallery/img_Moettingen.jpg"),
      },
      {
        title: "Harburg",
        image: require("@/assets/images/emobilitaet/gallery/img_Bachhagel.jpg"),
      },
      {
        title: "Täferrot",
        image: require("@/assets/images/emobilitaet/gallery/img_Taeferrot.jpg"),
      },
      {
        title: "Gundelfingen",
        image: require("@/assets/images/emobilitaet/gallery/img_Gundelfingen.jpg"),
      },
      {
        title: "Riesbürg",
        image: require("@/assets/images/emobilitaet/gallery/img_Riesbuerg.jpg"),
      },
      {
        title: "Ruppertshofen",
        image: require("@/assets/images/emobilitaet/gallery/img_Ruppertshofen.jpg"),
      },
    ],
    numberFrom: 0,
    numberTo: 140,
    duration: 5,
    scaleClass: false,
  }),

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    playAnimation() {
      $(".counter").each(function () {
        var count = $(this),
          zero = { val: 0 };
        gsap.to(zero, {
          duration: 2,
          val: count.data("number"),
          onUpdate: countNumber,
        });
        function countNumber() {
          var final = gsap.utils.snap(1, zero.val);
          count.text(final);
        }
      });
    },
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
          onEnter: this.playAnimation(),
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/emobilitaet.scss";
</style>
