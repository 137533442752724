<template>
  <div class="header">
    <h1><img src="../../assets/logo.png" alt="EnBW ODR" /></h1>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo",
};
</script>
<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 15px;
  text-align: left;
  z-index: 9;
  background: $greyColorLight;
  h1 {
    margin: 0;
    position: absolute;
    z-index: 3;
    max-width: 190px;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoint(mobile) {
      right: 15px;
      max-width: 150px;
    }
  }
}
</style>
