<template>
  <div class="home page">
    <video-background
      :src="require(`@/assets/videos/EnBW_ODR_2020_Drohneaufnahmen.mp4`)"
      style="height: calc(100vh)"
    >
      <section id="section-video">
        <h3>EnBW ODR AG</h3>
        <h2>Bericht 2021</h2>
        <div class="main-link">
          <router-link
            to="/aktionaersbrief"
            tag="div"
            class="link icon-arrow-right-after"
            ><a class="link--text">Aktionärsbrief</a></router-link
          >
        </div>
      </section>
    </video-background>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
<style lang="scss">
@import "./../scss/views/home.scss";
</style>
