<template>
  <div id="main-menu">
    <div class="navigation align-top">
      <slot></slot>
    </div>
    <div class="downloads">
      <div class="icon-pdf">
        <span class="fas fa-file-pdf"></span>
      </div>
      <a
        download
        href="../../assets/pdf/EnBW-ODR_Jahresbericht_2021_V17_web.pdf"
        target="_blank"
      >
        kompletter Bericht 2021
        <span>(6,3 MB)</span>
      </a>
    </div>
    <div class="links align-bottom">
      <a href="https://www.odr.de/impressum.html" target="_blank">Impressum</a>
      <span>·</span>
      <a href="https://www.odr.de/datenschutz.html" target="_blank"
        >Datenschutz</a
      >
      <span>·</span>
      <a href="https://www.odr.de" target="_blank">ww.odr.de</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
};
</script>
<style lang="scss" scoped>
@import "../../scss/global/_menu.scss";
</style>
