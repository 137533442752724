<template>
  <div class="page aktionaersbrief">
    <progress max="100" value="0"></progress>
    <section id="sec_02" class="section_01 section section__vh">
      <div class="container">
        <div class="content content__medium">
          <div class="item content-image">
            <div class="panel panel--shadow">
              <img src="../assets/images/vorstand.jpg" alt="" />
            </div>
          </div>
          <div class="item content-text">
            <div class="content-wrapper">
              <h2 class="cursive">
                Sehr geehrte Damen und Herren,<br />
                liebe Aktionär*innen, Mitarbeiter*innen und Freunde der ODR,
              </h2>
              <p>
                die furchtbaren Entwicklungen in der Ukraine lassen es nicht zu,
                diesen Aktionärsbrief mit der positiven Entwicklungsstory der
                ODR zu beginnen. Mitten in Europa führt der militärische
                Angriffskrieg von Russland auf das souveräne ukrainische Volk zu
                unfassbarem Leid, Tod und Zerstörung. Diese Ereignisse machen
                uns fassungslos und sehr nachdenklich. Die schrecklichen Bilder
                von Menschen auf der Flucht, die in Not und Angst um ihr Leben
                bangen, ist nicht zu ertragen und auch nicht zu akzeptieren. Wir
                hoffen, dass diese menschliche Verfehlung bald der Vergangenheit
                angehört. Unsere Solidarität und unser Mitgefühl – aller
                ODR-Mitarbeiter*innen – gilt den Menschen in der Ukraine.
              </p>
              <p>
                Als Energieunternehmen zwischen der Hohenloher Ebene und dem
                Donauried und zwischen dem Welzheimer Wald und dem Ries haben
                wir Vorsorge hinsichtlich möglicher Auswirkungen durch den Krieg
                auf die Menschen und das Gemeinwohl zu treffen. Mit Beginn der
                Kriegshandlungen haben wir eine Task Force gebildet und
                betrachten die Ereignisse mit hoher Intensität. Mit anderen
                Organisationen, Verbänden und der Politik sowie mit unserem
                EnBW-Konzern stehen wir in intensivem Austausch, um uns auf
                jegliche Szenarien vorzubereiten. Uns ist sehr wohl bewusst,
                dass wir eine große Verantwortung für die Versorgungssicherheit
                haben. Nicht nur das aktuelle Weltgeschehen führt zu einer
                deutlichen Beschleunigung der Energiewende. Die politische
                Entscheidung der neuen Bundesregierung, den Umbau der
                Energiewirtschaft zu forcieren, hat auch für uns entsprechende
                Konsequenzen. Für uns und unsere Tochter, die Netze ODR GmbH,
                liegen die Schwerpunkte neben der Versorgungssicherheit
                insbesondere auf dem Umbau der Energiewirtschaft. Der Netzausbau
                wird zu einem zentralen Thema für den Erfolg der Energiewende in
                unserer Region. Neben dem Umbau der Stromnetzinfrastruktur durch
                unsere Netze ODR steht insbesondere der Glasfaserausbau im
                Vordergrund.
              </p>
              <p>
                Die Gesellschaft und die Politik tragen das Ziel der
                Klimaneutralität mit breitem Konsens. Aus unserer Sicht ist zur
                Erreichung des ambitionierten Ziels die Entbürokratisierung der
                Genehmigungsverfahren sowohl beim Ausbau der erneuerbaren
                Energien als auch beim Stromnetzausbau notwendig. Politik und
                Gesellschaft müssen sich im Klaren sein, dass die konsequente
                Umsetzung nun Priorität bekommen muss. Die Aufgabe ist groß –
                die Zeit drängt.
              </p>
              <p>
                Die ODR und Netze ODR können die Forderungen der Politik und
                Gesellschaft erfüllen. Wir bieten Lösungen sowohl für den Umbau
                der Energieversorgung, als auch für die Mobilität der Zukunft
                und für die Herausforderungen der Wärmewende an.
              </p>
              <p>
                In der Transformation zur Klimaneutralität sehen wir riesige
                Chancen für die Menschen, die Kommunen und Unternehmen in
                unserer Region.
              </p>
              <p>
                Die vertrauensvolle Zusammenarbeit mit den Kommunen und Menschen
                in der Region spiegelt sich in den Konzessionen der Netze ODR
                wieder. Alle 16 Konzessionsverhandlungen im Jahr 2021 wurden
                erfolgreich abgeschlossen. Das ist die Basis, um die
                Energiewende in unserer Region gemeinsam mit den Kommunen zu
                gestalten.
              </p>
            </div>
            <div class="content-wrapper">
              <h3>2021 – das Jahr der Transformation</h3>
              <p>
                Im Jahr 2021 haben wir große Schritte bei der Transformation hin
                zum digitalen und nachhaltigen Energiedienstleister gemacht. Die
                Umstrukturierung der Netze ODR vom „schlanken“ zum „großen“
                Netzbetreiber stellt unsere Netztochter aus regulatorischer
                Sicht auf ein stabiles, finanzielles Fundament. Die
                wirtschaftlichen Vorteile werden ab der nächsten, der 4.
                Regulierungsperiode sichtbar und machen die ODR und die Netze
                ODR robuster.
              </p>
              <p>
                Zusammen mit dem EnBW-Konzern wurde 2021 das Projekt sCORE25
                erfolgreich umgesetzt. Die kaufmännischen und technischen
                Systemwelten und deren Kernprozesse wurden auf die SAP
                S/4HANA-Datenbanktechnologie gehoben. Eine Investition von rund
                zehn Millionen Euro in die digitale Zukunft. Zusätzlich wurde
                unsere seitherige HR-Systemwelt auf die Systeme des
                EnBW-Konzerns überführt.
              </p>
              <p>
                Mit diesen digitalen Transformationen haben wir einen Teil der
                Basis für die anstehenden Herausforderungen der Energiewende,
                wie sie die Bundesregierung bis 2030 fordert, geschaffen.
                Ergänzen werden wir diese digitale Transformation durch enorme
                Investitionen in die Infrastruktur. In den kommenden sieben bis
                zehn Jahren werden wir durch die Netze ODR über 35 Millionen
                Euro in den Hauptstandort in Ellwangen und in die dezentralen
                Bezirksstellen investieren.
              </p>
              <p>
                Alle diese Maßnahmen machen uns fit für die Umsetzung der
                Energiewende, sodass wir gemeinsam mit unseren Stakeholdern und
                Kunden die Energie neu denken können. Die grüne Energie
                dominiert inzwischen das Produktportfolio der ODR. Für unsere
                Industrieunternehmen sind wir der kompetente Partner
                hinsichtlich des Ausbaus von Ladenetzen für die E-Mobilität auf
                deren Unternehmensgelände. In unserer Region haben wir zusammen
                mit den Kommunen inzwischen ein umfassendes öffentliches
                E-Ladenetz aufgebaut. Gemeinsames Engagement mit den Kommunen
                zur Förderung des Breitbandausbaus ist ein wichtiger Eckpfeiler
                unserer Dienstleistungen. Hier tragen wir mit unserer Kompetenz
                durch die Errichtung von Infrastruktur zur weiteren
                wirtschaftlichen Entwicklung unserer Region bei.<br />
                Die Volatilität der Energie- und Beschaffungsmärkte,
                insbesondere im zweiten Halbjahr 2021, hat neben den bereits
                beschriebenen notwendigen Veränderungen zu einem negativen
                Ergebnis nach Steuern von 14,7 Millionen Euro geführt, das durch
                den Verlustausgleich der Muttergesellschaft EnBW Kommunale
                Beteiligungen GmbH ausgeglichen wird.
              </p>
              <p>
                Gestaltung der Energiewende mit einem kompetenten und
                innovativen Infrastrukturpartner Wir richten die ODR auf die
                Herausforderungen der Energiewende sowie die Modernisierung der
                Gesellschaft durch Breitband und Digitalisierung aus. Der Fokus
                liegt zunehmend auf dem Ausbau der dazu notwendigen
                Netzinfrastruktur durch unsere Netztochter und energienahen
                Geschäftsfeldern wie zum Beispiel E-Mobilität und Wärmelösungen.
                Dadurch erschließen sich uns neue Wachstumschancen. Die ODR wird
                in den nächsten Jahren noch ertragsstärker, robuster und
                nachhaltiger sein als heute. Die Ertüchtigung der
                Stromverteilnetze durch die Netze ODR als Basis für den Ausbau
                der erneuerbaren Energien steht dabei zweifelsohne im
                Mittelpunkt der Aktivitäten. Bis 2030 ist geplant, die
                Investitionen der Netze ODR von heute jährlich über 30 Millionen
                Euro auf über 60 Millionen Euro zu verdoppeln. Neben dieser
                herausfordernden Entwicklung wollen wir unsere
                Nachhaltigkeitsleistung stetig verbessern. Unser zentrales Ziel
                ist es, bis zum kommenden Jahr 2023 die Klimaneutralität zu
                erreichen. Den Umstieg von fossilem Gas auf grünen Wasserstoff
                treiben wir mit voran, um Gasnetze fit für die Zukunft zu
                machen. Aus technologischer und wirtschaftlicher Sicht eine
                anspruchsvolle Herausforderung.
              </p>
            </div>
            <div class="content-wrapper">
              <h3>Was ist uns wichtig?</h3>
              <p>
                Den Erfolg der ODR gemeinsam mit der Netze ODR erbringen unsere
                engagierten rund 600 Mitarbeiter* innen. Sie waren in den
                vergangenen Jahren und insbesondere im letzten Jahr besonders
                gefordert. Die eingeleiteten Veränderungen in unseren
                Unternehmen konnte nur durch ihr außergewöhnliches Engagement
                und Beharrlichkeit sowie Kreativität so erfolgreich auf den Weg
                gebracht werden. Dafür bedanken wir uns – die Vorstände Frank
                Reitmajer und Sebastian Maier – ganz herzlich. Wir wissen aber
                auch, dass die kommenden Jahre für uns noch sehr viele
                Herausforderungen mit sich bringen werden. Die Geschwindigkeit
                mit der sich unsere Welt verändern wird, nimmt noch mehr zu und
                gleichzeitig werden die Rahmenbedingungen wie Corona,
                Ukraine-Krieg und volatile Energie- und Beschaffungsmärkte noch
                schwieriger zu beurteilen sein. Uns ist jedoch bewusst, dass die
                Entwicklung und der Ausbau der Infrastrukturen in unserer Region
                maßgeblich zum wirtschaftlichen und gesellschaftlichen Erfolg
                beitragen wird. Wir wollen dabei eine wichtige Rolle spielen.
              </p>
              <p>
                Die ODR gemeinsam mit der Netze ODR und ihren Mitarbeiter*innen
                haben den Anspruch, die Verantwortung für diesen ökologischen,
                gesellschaftlichen und energiewirtschaftlichen
                Veränderungsprozess zu übernehmen.<br /><br />
              </p>
              <p>Wir stellen uns diesen Herausforderungen!<br /><br /><br /></p>
              <p>Mit freundlichen Grüßen</p>
            </div>
            <div class="content-wrapper">
              <h4 class="cursive">
                Frank Reitmajer <span class="separate"></span>Sebastian Maier
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/mission" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Aktionaersbrief",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        //const signature = contentBloc.querySelector('h4.cursive');
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
            //start: "bottom bottom",
            //end: "center center",
          },
        });
      });

      const signature = document.querySelector("h4.cursive");
      gsap.from(signature, {
        opacity: 0,
        duration: 2,
        scrollTrigger: {
          trigger: signature,
          toggleActions: "play pause resume reset",
        },
      });

      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const imgContent = document.querySelector(".content-image");
      gsap.from(imgContent, {
        scale: 0.4,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: imgContent,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/aktionaersbrief.scss";
</style>
