<template>
  <div class="page engagement">
    <progress max="100" value="0"></progress>
    <section class="section section_01 section__bg-white">
      <div class="main-bubble">
        <div class="bubble bubble__round-white">
          <h4>rund</h4>
          <h3><span class="counter" data-number="37.000">37.000</span> €</h3>
          <p>
            Spenden bei „Sehen<br />
            und helfen“
          </p>
        </div>
      </div>
      <div class="container">
        <div class="content-wrapper">
          <h1>Soziales Engagement</h1>
          <h2>Wir sind Teil der Gemeinschaft und packen mit an</h2>
          <p>
            Bei der ODR stehen wir füreinander ein und helfen uns gegenseitig.
            Dasselbe gilt für die Region, unsere Heimat. Wir leisten gerne
            unseren Beitrag dafür, dass sie für die Menschen lebens- und
            liebenswert bleibt: durch Unterstützung von Bildung, Kultur,
            Sozialem, Sport, Gesundheit und Sicherheit.
          </p>
          <p>
            Die Anzahl der neu angeschlossenen Erneuerbare-Energien-Anlagen im
            Netzgebiet der Netze NGO ist kräftig gestiegen. Gegenüber dem
            Vorjahr gab es annähernd 50 Prozent mehr neue Anlagen – so
            investierten die Bürger der Region 2020 in rund 1900 (Vorjahr über
            1200) neue Anlagen. Zum Jahresende 2020 speisen über 31.000
            Erzeugungsanlagen Strom in unser Netz ein. Rund 1,4 Milliarden kWh
            wurden bei einer installierten Leistung von über 950 MW ins Netz
            eingespeist.
          </p>
          <p>
            Die Vergütung an die Anlagenbetreiber beträgt nahezu 319,2 Millionen
            Euro.
          </p>
          <p>
            Mittlerweile entspricht die gesamte Einspeisung bilanziell über 71
            Prozent der Netzabgabe an unsere Letztverbraucher im Privat- und
            Industriekundensegment. Somit haben wir die politische Zielvorgabe
            für das Jahr 2030 frühzeitig erreicht.
          </p>
        </div>
        <div class="content main-gallery">
          <vueper-slides
            class="no-shadow"
            autoplay
            :visible-slides="2"
            :gap="5"
            :bullets="false"
            :slide-ratio="1 / 3"
            :infinite="false"
            :duration="4000"
            :dragging-distance="20000"
            :breakpoints="{
              //1023: { visibleSlides: 3, slideRatio: 1 / 4 },
              800: { visibleSlides: 2, slideRatio: 1 / 3 },
              500: { visibleSlides: 1, slideRatio: 2 / 3 },
            }"
          >
            <vueper-slide
              v-for="(slide, i) in slides"
              :key="i"
              :image="slide.image"
              :title="slide.title"
              :content="slide.content"
            />
          </vueper-slides>
        </div>
      </div>
    </section>
    <section class="section section__bg-grey">
      <div class="container">
        <div class="main-blog">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="../assets/images/engagement/img_blog_01.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="blog-text">
              <h2>Jung begeistern</h2>
              <h3>Bildungspartner kooperieren mit Schulen</h3>
              <p>
                Die Digitalisierung verändert unser Privat- und Berufsleben
                rasant; es gilt, junge Menschen früh für digitale Lösungen zu
                begeistern und sie fit zu machen für Zukunftsberufe. Gemeinsam
                mit der NetCom BW und J. Rettenmaier & Söhne haben wir deshalb
                im Jahr 2021 der Eugen-Bolz-Realschule in Ellwangen insgesamt
                5.000 Euro für eine industrielle Mini-Fertigungsstraße
                gespendet. Damit können die Jugendlichen Produkte programmieren
                und herstellen. 2.000 Euro davon kamen von der EnBW ODR.
              </p>
              <p>
                zen als regionales Energieunternehmen vor allem das Ehrenamt in
                der Region, darunter Sportvereine sowie soziale und kulturelle
                Projekte. Darüber hinaus fördern wir die Lebensqualität in
                unserem ländlichen Raum.
              </p>
            </div>
          </div>
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="../assets/images/engagement/img_blog_03.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="blog-text">
              <h2>Sozial engagiert –</h2>
              <h3>Auszubildende mobilisieren zum Blut spenden</h3>
              <p>
                In der Ausbildung des eigenen Nachwuchses sehen wir einen
                wichtigen Grundstein für die Zukunft unseres Unternehmens. Neben
                der Wissensvermittlung ist uns die Entwicklung der
                Persönlichkeit ein wichtiges Anliegen. Dabei legen wir vor allem
                Wert auf soziale Kompetenz, Teamfähigkeit und
                Verantwortungsbewusstsein. Im Berichtsjahr haben unsere
                Auszubildenden trotz Corona-Beschränkungen wieder ihre
                Blutspendeaktion realisiert; 79 Kolleginnen und Kollegen nahmen
                daran teil.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section__bg-white">
      <div class="container content-wrapper">
        <h2>Motiviert gearbeitet –</h2>
        <h3>Befragung belegt gute Unternehmenskultur</h3>
        <div class="main-bubble text-center main-bubble__float-r">
          <div class="bubble bubble__round-white">
            <h4>ODR und Netze ODR bieten</h4>
            <h3>über <span class="counter" data-number="600">600</span></h3>
            <p>
              Menschen zukunftsfähige<br />
              Arbeitsplätz
            </p>
          </div>
        </div>
        <p>
          Konzentriert auf unseren Zusammenhalt, Motivation, Flexibilität und
          Identifikation mit dem Unternehmen, haben wir die enormen
          Herausforderungen im Berichtsjahr bewältigt. Das Vertrauen in die
          Zukunftsfähigkeit unseres Unternehmens wurde gestärkt und somit unsere
          Arbeitgeber-attraktivität im Berichtsjahr weiterhin positiv
          beeinflusst.
        </p>
        <p>
          <strong>Engagement der Mitarbeiter*innen<br /></strong>
          Unsere Unternehmenskultur ist durch Teamgeist, Zugehörigkeit und
          Wertschätzung geprägt. Sie ist Basis für die Zufriedenheit und die
          Motivation unserer Mitarbeitenden. Beides ist ausgewiesen hoch: In
          unserer Mitarbeiterbefragung messen wir seit 2020 den People
          Engagement Index (PEI). Er hat im Jahr 2021 den Wert von 79 Punkten
          auf einer Skala von 0 bis 100 erreicht und liegt damit im Vergleich
          über dem Durschnitt. Das hohe Engagement unserer Mitarbeitenden hat
          maßgeblich dazu beigetragen, dass wir unsere Ziele im Berichtsjahr
          erreicht haben und Transformations- und Digitalisierungsprozesse
          erfolgreich umsetzen konnten.
        </p>
        <p>
          <strong>
            270 Mitarbeitende sowie 35 Auszubildende wechseln von der EnBW ODR
            AG zur Netze ODR GmbH.<br />
          </strong>
          Mit der Ausgliederung des Geschäftsbereichs Netzwirtschaft auf unsere
          100%ige Tochtergesellschaft, die Netze NGO GmbH, haben zum 4. Oktober
          2021 rund 270 Mitarbeiter*innen sowie 35 Auszubildende zur Netze ODR
          gewechselt, die sich bei ihrem neuen Arbeitgeber um alle Themen und
          Aufgaben der Strom- und Gas-Infrastruktur kümmern.
        </p>
        <p>
          <img
            src="../assets/images/engagement/table-1.jpg"
            alt=""
            srcset=""
            class="mt-4 mb-4"
          />
        </p>
        <p>
          <strong>
            Umstellung auf eine moderne und digitalisierte HR-Welt<br />
          </strong>
          Im Berichtsjahr wurden durch das Projekt „ODR@EnBW-HR“ HR-Kernprozesse
          konsequent digitalisiert und vereinfacht.<br />
          Zum Jahreswechsel 2021/2022 wurde nach einem Jahr intensiver
          Projektarbeiter der Wechsel auf das HR-System der EnBW vollzogen. Mit
          dem erfolgreichen Projektabschluss nutzt die ODR zukünftig innovative
          HR-Produkte der EnBW. <br />
          Da die Digitalisierung der Arbeitswelt maßgeblich auch über die
          Attraktivität eines Arbeitsgebers entscheidet, haben wir durch die
          grundlegende Veränderung unserer HR-Prozesse einen wichtigen Beitrag
          zur Steigerung unserer Arbeitgeberattraktivität geleistet.
        </p>
        <p>
          <strong>
            Systematisch gefördert – wir entwickeln unsere Mitarbeiter<br />
          </strong>
          Wir investieren durchgehend in die Weiterentwicklung unserer
          Beschäftigten durch gezielte interne wie externe Fortbildung. Dabei
          setzen wir auf einen engen Austausch zwischen Mitarbeitenden und
          Führungskraft. Für den Erfolg unserer Weiterbildung spricht, dass wir
          Führungspositionen häufig aus den eigenen Reihen besetzen. Die
          Kompetenz unserer Mitarbeitenden und ihre Persönlichkeit sind
          entscheidend für die Zukunft unseres Unternehmens.
        </p>
        <p>
          <strong>Wir sind ausgezeichnet familienfreundlich<br /> </strong>
          Durch mitarbeiterfreundliche Arbeitszeitmodelle wie flexible
          Arbeitszeiten, Teilzeit- und Homeoffice-Optionen punkten wir bei
          unseren Mitarbeiter*innen.
        </p>
        <p>
          <strong>Gesundheit ist das höchste Gut<br /> </strong>
          Bei unserem nachhaltigen Gesundheitsmanagement geht es uns um mehr als
          den Erhalt der Arbeitskraft durch Prävention. Uns ist es wichtig,
          Mitarbeiter*innen für die sich kontinuierlich wandelnde Arbeitswelt
          ganzheitlich zu befähigen. Zu den gesundheitsfördernden
          Rahmenbedingungen zählen auch vielfältige Kurse zum Thema Gesundheit.
        </p>
        <div class="bloc-zitat">
          <p>
            Die Arbeitswelt der EnBW ODR und der Netze ODR ist so bunt und
            vielfältig wie das Leben: Hier bringt man die Energiewende voran,
            bildet aus, entwickelt neue Produkte, verkauft Strom und Gas, berät
            Kunden, fördert Breitbandausbau mit Kommunen, baut Stromtankstellen
            und Windenenergieparks, steht im Kabelgraben, steuert Netze, managt
            Personal und schmiedet Zukunftspläne.
          </p>
        </div>
      </div>
    </section>
    <section class="section section__bg-grey">
      <div class="container">
        <div class="main-blog">
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="../assets/images/engagement/img_blog_04.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="blog-text">
              <h2>Zusammen anpacken –</h2>
              <h3>Kollegen helfen nach Hochwasser im Ahrtal</h3>
              <p>
                „Wir treten gegenseitig füreinander ein und helfen, wo Hilfe
                geboten ist, wie unser Arbeitgeber auch“, sagt Franz Stölzle,
                Bereichsleiter Betrieb Netze. Insgesamt neun Mitarbeiter haben
                den Ahrtal-Werken nach der Flutkatastrophe im Ahrtal unter die
                Arme gegriffen und beim Wiederaufbau der Stromversorgung
                geholfen. Sie waren schockiert von der Verwüstung vor Ort, aber
                auch tief beeindruckt von der Solidarität und Menschlichkeit der
                vielen Helfenden.
              </p>
            </div>
          </div>
          <div class="blog-item">
            <div class="blog-img">
              <img
                src="../assets/images/engagement/img_blog_05.jpg"
                alt=""
                srcset=""
              />
            </div>
            <div class="blog-text">
              <h3>Hilfe für Ukraine Flüchtende</h3>
              <p>
                Die EnBW ODR unterstützt Anfang Februar 2022 den Tannhauser
                Verein Hope for Africa e. V. bei der Realisierung eines
                Hilfskonvois mit zwei VW Bussen, der diverse Hilfsgüter in die
                Grenzregion zwischen Polen und der Ukraine brachte. Menschen,
                die aus der Ukraine fliehen mussten, wurden angekommen in
                Deutschland, im Ostalbkreis in sichere Unterkünfte im Raum
                Tannhausen – Unterschneidheim – Röhlingen untergebracht.
                ODR-Vorstand Sebastian Maier betont: „Mit dieser Hilfsaktion
                können wir einen kleinen Beitrag gegen die Ohnmacht über das
                unglaubliche Leid der ukrainischen Bevölkerung, die jeder von
                uns verspürt, leisten.“
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/netzeodr" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/wertschoepfung" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//import $ from "jquery";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Engagement",
  components: { VueperSlides, VueperSlide },

  data: () => ({
    slides: [
      {
        title: "Sehen und helfen Ellwangen, ambulanter Hospizdienst",
        image: require("@/assets/images/engagement/gallery/img_01.jpg"),
      },
      {
        title: "Sehen und helfen Giengen",
        image: require("@/assets/images/engagement/gallery/img_02.jpg"),
      },
      {
        title: "Sehen und helfen Aalen",
        image: require("@/assets/images/engagement/gallery/img_03.jpg"),
      },
      {
        title: "Sehen und helfen Nördlingen",
        image: require("@/assets/images/engagement/gallery/img_04.jpg"),
      },
      {
        title: "Sehen und helfen Schwäbisch Hall",
        image: require("@/assets/images/engagement/gallery/img_05.jpg"),
      },
      {
        title: "Sehen und helfen Dillingen",
        image: require("@/assets/images/engagement/gallery/img_06.jpg"),
      },
      {
        title: "Sehen und helfen Welzheim",
        image: require("@/assets/images/engagement/gallery/img_07.jpg"),
      },
      {
        title: "Sehen und helfen Heidenheim",
        image: require("@/assets/images/engagement/gallery/img_08.jpg"),
      },
    ],
    numberFrom: 0,
    numberTo: 140,
    duration: 5,
    scaleClass: false,
  }),

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const tl = gsap.timeline({ defaults: { duration: 0.7, opacity: 0 } });
      tl.from("#ard_r, #ard_l", { delay: 0, y: -50 });
      tl.from("#ven", { delay: 0.5, scaleY: 0, transformOrigin: "bottom" });
      tl.from("#mann", { delay: 0.5, y: -100 }, "=.1");

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const animCharts = gsap.utils.toArray(".anim-chart");
      animCharts.forEach((animChart) => {
        gsap.from(animChart, {
          scale: 0,
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: animChart,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/engagement.scss";
</style>
