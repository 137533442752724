<template>
  <div class="page erneuerbare">
    <progress max="100" value="0"></progress>
    <video-background
      :src="require(`@/assets/videos/Windpark_Rot_am_See_Referenz.mp4`)"
      style="max-height: 600px; min-height: 600px; height: calc(100vh - 350px)"
    >
      <section id="section-video">
        <h2>Mehr Aufwind für grüne Energien</h2>
      </section>
    </video-background>
    <section class="section section_02 section__bg-grey">
      <div class="container">
        <div class="content-wrapper">
          <h2>Grün fokussiert –</h2>
          <h3>Erneuerbare Energien und Pflanzen sorgen für gutes Klima</h3>
          <p>
            Wärme und Strom erhält der neue Bürokomplex über ein neues,
            hocheffizientes Blockheizkraftwerk auf dem Firmengelände, das auch
            weitere Gebäude auf dem ODR-Campus mit Energie versorgen kann. Hinzu
            kommt eine Photovoltaikanlage auf dem Dach für den Eigenverbrauch.
            Im Foyer sorgt ein vertikaler Garten für optimale Luftfeuchtigkeit,
            gute Raum-akustik und gefilterte Luft. Die Florawall symbolisiert
            auch, wofür wir als EnBW ODR stehen: für Nachhaltigkeit und
            Modernität.
          </p>
          <p>
            <strong>
              Formell ausgezeichnet – unsere Nachwuchskräfte sind spitze <br />
            </strong>
            Im Jahr 2021 haben acht junge Menschen ihre Ausbildung bei unserer
            Netztochter Netze ODR abgeschlossen, vier davon mit Auszeichnung
            durch die IHK Ostwürttemberg. Alle Acht haben wir in ein
            unbefristetes Arbeitsverhältnis übernommen. Die frisch gebackenen
            Elektroniker, Industriekaufleute und Informatiker gestalten die
            Energiewende aktiv mit und haben hervorragende Perspektiven für eine
            aussichtsreiche Karriere. Wir begegnen dem Fachkräftemangel mit
            eigener Ausbildung – für eine gute Zukunft der ODR.
          </p>
          <p>
            <strong>
              Zukunftssicher aufgestellt – wir bündeln unsere Ausbildung in der
              Netzgesellschaft
              <br />
            </strong>
            Die Arbeitswelt der EnBW ODR und der Netze ODR ist so bunt und
            vielfältig wie das Leben: Hier bringt man die Energiewende voran,
            bildet aus, entwickelt neue Produkte, verkauft Strom und Gas, berät
            Kunden, fördert Breitbandausbau mit Kommunen, baut Stromtankstellen
            und Windenenergieparks, steht im Kabelgraben, steuert Netze, managt
            Personal und schmiedet Zukunftspläne.
          </p>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/mitarbeiter" tag="div">
          <a class="link link__back"></a>
        </router-link>
        <router-link to="/bilanz" tag="div">
          <a class="link link__next"></a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Erneuerbare",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/erneuerbare.scss";
</style>
