<template>
  <div class="page mitarbeiter">
    <progress max="100" value="0"></progress>
    <section class="section section__bg-white section__vw section__vh">
      <div class="main-banner jarallax">
        <img
          src="../assets/images/mitarbeiter/banner.jpg"
          alt
          class="jarallax-img"
        />
        <div class="banner-content">
          <div class="container">
            <h2>
              Modernes Umfeld<br />
              für Spaß an der Arbeit
            </h2>
          </div>
        </div>
      </div>
      <div class="container container__pw">
        <div class="content-wrapper">
          <h2>Wir fördern und fordern fachliches Wissen und Persönlichkeit</h2>
          <p>
            Die Aufgaben eines Energie- und Dienstleistungsunternehmens wie der
            EnBW ODR werden immer komplexer und vernetzter, die Geschwindigkeit
            der Veränderung nimmt zu. Unsere Mitarbeiterinnen und Mitarbeiter
            sollen sich wohl fühlen und ihr volles Potential entfalten. Deshalb
            bieten wir ihnen ein modernes und gesundes Arbeitsumfeld, indem sich
            Familie und Job gut unter einen Hut bringen lassen.
          </p>
        </div>
      </div>
    </section>
    <section class="section section_02 section__bg-white">
      <div class="jarallax jarallax_section">
        <img
          src="../assets/images/mitarbeiter/parallax_section.jpg"
          alt
          class="jarallax-img"
        />
        <div class="jarallax_content">
          <div class="container">
            <div class="bloc-text bg-white shadow">
              <p>
                „Der Zusammenhalt im Unternehmen ist sehr stark. Jeder hilft
                jedem“,
                <strong>
                  so Sebastian Maier, technischer Vorstand der ODR.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section_03 section__bg-grey">
      <div class="container">
        <div class="content-wrapper">
          <h2>Sichtbar nachhaltig –</h2>
          <h3>Neues Bürogebäude setzt Zeichen</h3>
          <p>
            Am 24. September 2021 haben wir eine neue Arbeitsheimat für rund 150
            Kolleginnen und Kollegen eingeweiht: unser neues Bürogebäude auf dem
            Ellwanger ODR-Campus. Der Bau besticht durch seine hohe
            Energieeffizienz und nachhaltige Baumaterialien sowie durch sein
            modernes Raumkonzept für agiles Arbeiten: Kommunikationsinseln
            fördern Austausch und Kreativität, geschützte Rückzugsecken
            konzentriertes Arbeiten. Dazu zählen auch die Open air-Arbeitsplätze
            in unserem Garten.
          </p>
          <p>
            <strong>
              Innovativ ausbauen – neue Arbeitsmodelle fördern
              Leistungsfähigkeit<br />
            </strong>
            „Die Aufgaben, die wir für das Gelingen der Energie- und Klimawende
            leisten müssen, werden immer komplexer und vernetzter, die
            Geschwindigkeit der Veränderung nimmt zu“, betont ODR- Vorstand
            Frank Reitmajer. „In dem neuen Gebäude können wir mit neuen
            Arbeitsmodellen unsere Innovationsfähigkeit weiter ausbauen. Neues
            Arbeiten heißt für uns: Flexibilität bei Ort und Zeit,
            selbstbestimmtes und freies Arbeiten, Work-Life-Balance, Kreativität
            gegen Automatisierung“.
          </p>
          <p>
            <strong>
              Fit arbeiten – wir schaffen ein gesundes Arbeitsklima<br />
            </strong>
            Wir bieten unseren Mitarbeitenden ein gesundes Arbeitsumfeld, dazu
            gehören eine Vielzahl an Kursen zum Thema Gesundheit. Aber auch
            familienfreundliche Arbeitszeitmodelle wie flexible Arbeitszeiten,
            Teilzeit- und Homeoffice-Optionen gehören für uns dazu – die
            seelische Gesundheit unserer Beschäftigten ist uns wichtig. Wir sind
            als familienfreundlicher Arbeitgeber ausgezeichnet.
          </p>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/wertschoepfung" tag="div">
          <a class="link link__back"></a>
        </router-link>
        <router-link to="/erneuerbare" tag="div">
          <a class="link link__next"></a>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import "object-fit-images";
import { jarallax, jarallaxVideo } from "jarallax";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Mitarbeiter",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });

      const contentBlocs = gsap.utils.toArray(".content-wrapper");
      contentBlocs.forEach((contentBloc) => {
        gsap.from(contentBloc, {
          y: 50,
          opacity: 0,
          duration: 1,
          scrollTrigger: {
            trigger: contentBloc,
            toggleActions: "play pause resume reset",
          },
        });
      });

      const bubble = document.querySelector(".main-bubble");
      gsap.from(bubble, {
        scale: 0,
        opacity: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: bubble,
          toggleActions: "play pause resume reset",
          start: "bottom bottom",
          end: "top top",
        },
      });

      jarallaxVideo();
      jarallax(document.querySelectorAll(".jarallax"), {
        speed: 0.2,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/mitarbeiter.scss";
</style>
