import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Aktionaersbrief from "../views/Aktionaersbrief.vue";
import Mission from "../views/Mission.vue";
import Preisentwicklung from "../views/Preisentwicklung.vue";
import Menschundnatur from "../views/Menschundnatur.vue";
import Wertschoepfung from "../views/Wertschoepfung.vue";
import Emobilitaet from "../views/Emobilitaet.vue";
import Engagement from "../views/Engagement.vue";
import Netzeodr from "../views/Netzeodr.vue";
import Erneuerbare from "../views/Erneuerbare.vue";
import Mitarbeiter from "../views/Mitarbeiter.vue";
import Bilanz from "../views/Bilanz.vue";
import Gewinn from "../views/Gewinn.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/aktionaersbrief",
    name: "Aktionaersbrief",
    component: Aktionaersbrief,
  },
  {
    path: "/preisentwicklung",
    name: "Preisentwicklung",
    component: Preisentwicklung,
  },
  {
    path: "/mission",
    name: "Mission",
    component: Mission,
  },
  {
    path: "/menschundnatur",
    name: "Menschundnatur",
    component: Menschundnatur,
  },
  {
    path: "/emobilitaet",
    name: "Emobilitaet",
    component: Emobilitaet,
  },
  {
    path: "/engagement",
    name: "Engagement",
    component: Engagement,
  },
  {
    path: "/netzeodr",
    name: "Netzeodr",
    component: Netzeodr,
  },
  {
    path: "/wertschoepfung",
    name: "Wertschöpfung",
    component: Wertschoepfung,
  },
  {
    path: "/erneuerbare",
    name: "Erneuerbare",
    component: Erneuerbare,
  },
  {
    path: "/mitarbeiter",
    name: "Mitarbeiter",
    component: Mitarbeiter,
  },
  {
    path: "/bilanz",
    name: "Bilanz",
    component: Bilanz,
  },
  {
    path: "/gewinn",
    name: "Gewinn",
    component: Gewinn,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(savedPosition) {
    console.log("savedPosition", savedPosition);
    if (savedPosition) {
      return savedPosition;
    } else if (savedPosition === null) {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
