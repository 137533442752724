<template>
  <div class="page">
    <progress max="100" value="0"></progress>
    <section class="content-table">
      <div class="container">
        <h2>Bilanz zum 31. Dezember 2021</h2>
        <div class="mainflex header border-2 border-top">
          <div class="item iteam-2s bold">in T€</div>
          <div class="item bold new">31.12.2021</div>
          <div class="item bold">Vorjahr</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s bold">Aktiva</div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <!-- A. Anlagevermögen -->
        <div class="mainflex border-2">
          <div class="item iteam-2s bold">A. Anlagevermögen</div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">I. Immaterielle Vermögensgegenstände</div>
          <div class="item new">5.577</div>
          <div class="item">9.028</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">II. Sachanlagen</div>
          <div class="item new">3.191</div>
          <div class="item">302.779</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">III. Finanzanlagen</div>
          <div class="item new">206.198</div>
          <div class="item">46.583</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s"></div>
          <div class="item new">214.966</div>
          <div class="item">358.390</div>
        </div>
        <!-- B. Umlaufvermögen -->
        <div class="mainflex border-2">
          <div class="item iteam-2s bold">B. Umlaufvermögen</div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">I. Vorräte</div>
          <div class="item new">3.413</div>
          <div class="item">3.201</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">
            II. Forderungen und sonstige Vermögensgegenstände
          </div>
          <div class="item new">44.604</div>
          <div class="item">49.116</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">III. Guthaben bei Kreditinstituten</div>
          <div class="item new">4.213</div>
          <div class="item">7.046</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s"></div>
          <div class="item new">52.018</div>
          <div class="item">59.575</div>
        </div>
        <div class="mainflex summe">
          <div class="item iteam-2s leer"></div>
          <div class="item new">266.984</div>
          <div class="item">417.965</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s bold">Passiva</div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <!-- A. Eigenkapital -->
        <div class="mainflex border-2">
          <div class="item iteam-2s bold">A. Eigenkapital</div>
          <div class="item bold new leer"></div>
          <div class="item bold leer"></div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">I. Gezeichnetes Kapital</div>
          <div class="item new">30.310</div>
          <div class="item">30.310</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">II. Kapitalrücklage</div>
          <div class="item new">72.551</div>
          <div class="item">72.551</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s">III. Gewinnrücklage</div>
          <div class="item new">12.554</div>
          <div class="item">12.554</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s">IV. Bilanzgewinn</div>
          <div class="item new">24</div>
          <div class="item">24</div>
        </div>
        <div class="mainflex">
          <div class="item iteam-2s leer"></div>
          <div class="item new">115.439</div>
          <div class="item">115.439</div>
        </div>
        <div class="mainflex seprate">
          <div class="item iteam-2s"></div>
          <div class="item new"></div>
          <div class="item"></div>
        </div>
        <!-- B. Baukostenzuschüsse -->
        <div class="mainflex">
          <div class="item iteam-2s bold">B. Baukostenzuschüsse</div>
          <div class="item new">3</div>
          <div class="item">50.314</div>
        </div>
        <!-- C. Rückstellungen -->
        <div class="mainflex">
          <div class="item iteam-2s bold">C. Rückstellungen</div>
          <div class="item new">104.105</div>
          <div class="item">201.984</div>
        </div>
        <!-- D. Verbindlichkeiten -->
        <div class="mainflex">
          <div class="item iteam-2s bold">D. Verbindlichkeiten</div>
          <div class="item new">43.170</div>
          <div class="item">45.748</div>
        </div>
        <!-- E. Rechnungsabgrenzungsposten -->
        <div class="mainflex">
          <div class="item iteam-2s bold">E. Rechnungsabgrenzungsposten</div>
          <div class="item new">4.267</div>
          <div class="item">4.480</div>
        </div>
        <div class="mainflex border-2">
          <div class="item iteam-2s leer"></div>
          <div class="item new">266.984</div>
          <div class="item">417.965</div>
        </div>
      </div>
    </section>
    <div class="main-nav-section">
      <div class="container">
        <router-link to="/mitarbeiter" tag="div"
          ><a class="link link__back"></a
        ></router-link>
        <router-link to="/gewinn" tag="div"
          ><a class="link link__next"></a
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "Bilanz",
  components: {},

  mounted: function () {
    window.scrollTo(0, 0);
    this.scrollAnimation();
  },
  methods: {
    scrollAnimation() {
      gsap.to("progress", {
        value: 100,
        ease: "none",
        scrollTrigger: { scrub: 0.15 },
      });
    },
  },
};
</script>

<style lang="scss">
@import "./../scss/views/bilanz.scss";
</style>
