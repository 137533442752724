<template>
  <div id="app" :class="{ show: showSidebar }">
    <HeaderLogo></HeaderLogo>
    <BurgerMenu></BurgerMenu>
    <Sidebar>
      <ul>
        <router-link @click.native="showMenuTimeOut" to="/" tag="li">
          <a>Home</a>
        </router-link>
        <router-link
          @click.native="showMenuTimeOut"
          to="/aktionaersbrief"
          tag="li"
        >
          <a>Aktionärsbrief</a>
        </router-link>
        <!-- Mission -->
        <router-link @click.native="showMenuTimeOut" to="/mission" tag="li">
          <a>Mission</a>
        </router-link>
        <!-- Preisentwicklung -->
        <router-link
          @click.native="showMenuTimeOut"
          to="/preisentwicklung"
          tag="li"
        >
          <a>Preisentwicklung</a>
        </router-link>
        <!-- Menschundnatur -->
        <router-link
          @click.native="showMenuTimeOut"
          to="/menschundnatur"
          tag="li"
        >
          <a>Respekt vor Mensch und Natur</a>
        </router-link>
        <!-- E-Mobilität -->
        <router-link @click.native="showMenuTimeOut" to="/emobilitaet" tag="li">
          <a>Infrastrukturen für E-Mobilität und schnelles Internet</a>
        </router-link>
        <router-link
          @click.native="showMenuTimeOut"
          to="/netzeodr"
          tag="li"
          class="link-netz"
        >
          <a
            >Netze ODR GmbH – <br />
            Investitionen in die Versorgungsqualität</a
          >
        </router-link>
        <!--  Engagement -->
        <router-link @click.native="showMenuTimeOut" to="/engagement" tag="li">
          <a>Soziales Engagement</a>
        </router-link>
        <!-- Wertschoepfung -->
        <router-link
          @click.native="showMenuTimeOut"
          to="/wertschoepfung"
          tag="li"
        >
          <a>Wertschöpfung für die Region</a>
        </router-link>
        <!-- Mitarbeiter -->
        <router-link @click.native="showMenuTimeOut" to="/mitarbeiter" tag="li">
          <a>Modernes Umfeld für Spaß an der Arbeit</a>
        </router-link>
        <!-- Erneuerbare Energien -->
        <router-link @click.native="showMenuTimeOut" to="/erneuerbare" tag="li">
          <a>Erneuerbare Energien</a>
        </router-link>
        <router-link @click.native="showMenuTimeOut" to="/bilanz" tag="li">
          <a>Bilanz</a>
        </router-link>
        <router-link @click.native="showMenuTimeOut" to="/gewinn" tag="li">
          <a>Gewinn- und Verlustrechnung</a>
        </router-link>
      </ul>
    </Sidebar>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import HeaderLogo from "@/components/global/HeaderLogo";
import BurgerMenu from "@/components/global/BurgerMenu";
import Sidebar from "@/components/global/Sidebar";
import $ from "jquery";

export default {
  name: "Home",
  data() {
    return {
      showSidebar: false,
    };
  },
  components: {
    HeaderLogo,
    BurgerMenu,
    Sidebar,
  },
  methods: {
    showMenuTimeOut: () => {
      if (window.innerWidth < 768) {
        $("#app").toggleClass("show");
        $(window).scrollTop(0);
      }
    },
  },
};
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 5s;
}

.fade-enter-active {
  transition-delay: 5s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
